<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>Тестковые поля</v-toolbar-title>
            </v-toolbar>
            <v-select
                v-model="entity.page"
                label="Страница показа"
                :items="pages"
                item-text="title"
                item-value="value"
                outlined
            />
            <template v-for="(value, index) in entity.values">
                <v-col
                    :key="index"
                    cols="12"
                    class="pa-0">
                    <div
                        class="pa-1 mx-0 my-2"
                        style="border: 1px solid #00000061; border-radius: 4px;">
                        <v-row class="ma-0 d-flex justify-center align-center">
                            <v-text-field
                                v-model="value.name"
                                label="Код поля"
                                hide-details
                                outlined
                                class="ma-1"
                            />
                            <v-btn
                                color="error"
                                @click="removeValue(index)"
                                class="ma-1"
                            >Удалить</v-btn>
                        </v-row>
                        <v-row class="ma-0">
                            <v-textarea
                                v-model="value.text"
                                label="Текст"
                                hide-details
                                outlined
                                class="ma-1"
                            />
                        </v-row>
                    </div>
                </v-col>
            </template>
            <v-row
                align="center"
                justify="space-between"
                class="mx-0 my-2">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="addValue">Добавить поле</v-btn>
            </v-row>
            <v-row
                align="center"
                justify="space-between"
                class="mt-4 mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">Сохранить</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">Удалить</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc } from '../../helpers';
    import { PAGE, PAGE_TITLE } from '../../vars';

    export default {
        name: 'TitlesEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            PAGE,
            PAGE_TITLE,
            imageSrc,
            valid: true,
        }),
        computed: {
            ...mapState('titles', ['entity']),
            pages() {
                const pages = [];
                for(const key in PAGE_TITLE) {
                    pages.push({
                        value: key,
                        title: PAGE_TITLE[key]
                    });
                }
                return pages;
            }
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('titles/get', { id: this.$route.params.id });
            } else {
                await store.commit('titles/CLEAR_ENTITY');
            }
        },
        methods: {
            addValue() {
                this.entity.values.push({
                    code: '',
                    text: ''
                });
            },
            removeValue(index) {
                this.entity.values.splice(index, 1);
            },
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('titles/SET_ENTITY', this.entity);
                    const response = await store.dispatch('titles/save');
                    if(response.error) {
                        alert(response.error);
                    } else {
                        await this.$router.push({ name: 'titles-list' });
                    }
                }
            },
            async remove() {
                await store.dispatch('titles/delete', { id: this.entity._id });
                await this.$router.push({ name: 'titles-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
    .titles__content__block {
        margin: 10px 0;
        padding: 20px;
        border: 1px solid #cecece;
        border-radius: 4px;
    }
</style>
